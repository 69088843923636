#storageFrame {
	position: absolute;
	height: 1px;
	width: 1px;
	left: -999;
	opacity: 0;
}

.App {
	height: 100vh;
	width: 100vw;
	background-color: rgb(20, 20, 20);
	padding-top: 200px;
}

.App input {
	padding: 15px 30px;
	border-radius: 5px;
	border: 0px solid transparent;
	width: 300px;
	display: block;
	margin: 0 auto 5px auto;
}

.App button {
	display: block;
	margin: 0 auto;
	padding: 10px 30px;
	border-radius: 5px;
	border: 0px solid transparent;
	margin-top: 10px;
}

.App h2 {
	color: #fff;
	text-align: center;
	font-weight: 200;
}
